import React, { Component } from 'react'
import { withTranslation, useTranslation } from 'react-i18next'
import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'

import {MyLoader, ButtonGoUp } from '../CommonComponents'
import { resizeNavBar } from '../CommonFunctions'



//reveal-effect
import Bounce from 'react-reveal/Bounce';



let readMoreUrl = 'https://catwatchful.es/android-client'
let appUrl = 'https://catwatchful.pink/apk/cwf_client.apk'


class AlertSettings extends Component {




    constructor(props) {
        super(props)

        this.state = ({
            wsAllFeatures: null,//contiene todas las features de catwatchful (lenght=27)
            wsFeatureNotify: null//contiene (en true) las features que debo notificar  (lenght=27)
        })
    }


    componentDidMount() {
        resizeNavBar()

        this.loadDataFromWebService()
    }



    loadDataFromWebService = () => {

        //Consulta1:
        const operation1 = "getFeaturesInf"
        UseWebService("operation=" + operation1).then(resp => {
            if (resp.statusCode == 200) {
                const arrayFeaturesInf = new Array(27)
                resp.data.map(ft => { if (ft) arrayFeaturesInf[ft.featureId] = ft })
                this.setState({ wsAllFeatures: arrayFeaturesInf })
            }
        })


        //Consulta2:
        const operation2 = "getClientNotifySettings"
        UseWebService("operation=" + operation2 + "&targetImei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode == 200) {
                //Construyo el array 

                const arrayFeatureNotify = new Array(27)
                for (let i = 1; i < arrayFeatureNotify.length; i++)arrayFeatureNotify[i] = false

                let features = resp.data[0].featuresToNotify.split("-");
                features.map(ft => { if (ft.length > 0) arrayFeatureNotify[ft] = true })

                this.setState({ wsFeatureNotify: arrayFeatureNotify })

            } else {
                this.setState({ wsFeatureNotify: [] })
            }
        })

    }


    handleSwitchToggle = (obj) => {

        const currentSwitchRef = document.getElementById(obj)

        const isChecked = currentSwitchRef.value.includes("#")//Si tiene este simbolo, es porque actualmente esta en check
        const featureId = currentSwitchRef.value.replace('#', '')


        let arrAux = this.state.wsFeatureNotify
        arrAux[featureId] = !isChecked//seteo opuesto a como esta actualmente
        this.setState({ wsFeatureNotify: arrAux })


        let settingsArray = ""//creo un string con los valores del array  (-1-4-3-7-4-2)
        for (let i = 0; i < arrAux.length; i++)
            if (arrAux[i])
                settingsArray += "-" + i

        if (settingsArray.length == 0) settingsArray = "-"


        //Envio actualizacion al webServer----
        const operation = "updateClientNotifySettings"
        UseWebServicePOST("operation=" + operation + "&userUid=" + this.context.user.uid + "&targetImei=" + this.context.activeDevice.imei + "&settingsArray=" + settingsArray)
        //------

    }

    handleReadMoreClick = () => {
        window.open(readMoreUrl, '_blank')
    }


    render() {
        const { t } = this.props

        if (!this.state.wsAllFeatures || !this.state.wsFeatureNotify) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h3 className='text-uppercase'>{t('alertSettings.title')}</h3>
                        <MyLoader text={t('alertSettings.loading')}/>
                    </div>

                </div>
            )


        } else {


            return (
                <div onClick={() => resizeNavBar()}>


                    <div className="row  justify-content-center">
                        <div className="col-12 col-md-6">

                        <h3 className='text-uppercase'>{t('alertSettings.title')}</h3>

                            <div className='mt-2 text-uppercase mb-2 mt-4'><i className='fa fa-warning text-warning mr-1'/>{t('alertSettings.androidClientTitle')}</div>

                            <div className="mb-1 mr-2 mr-md-3 " data-toggle="collapse" data-target="#collapseReadMore" aria-expanded="false" aria-controls="collapseReadMore">
                                <button className="btn btn-outline-secondary btn-sm" ><i className="fa fa-chevron-down mr-1" />Mas Información</button>
                            </div>

                            <div className="collapse" id="collapseReadMore" >

                                <div className="card bg-dark p-1 blueShadow" >
                                    {/* <img className="card-img-top" src="./images/android-client.jpg"/> */}
                                    <div className="card-body">
                                        <div className="card-text ">
                                            {t('alertSettings.androidClientDescription')}
                                            {/* <button onClick={this.handleReadMoreClick} className='btn btn-outline-secondary btn-sm mx-2'>{t('alertSettings.readMore')}</button> */}
                                        </div>


                                    </div>
                                    <ul className="list-group list-group-flush ">
                                        <div className="list-group-item bg-dark"><i className="fa fa-chevron-right text-warning mr-1" /><span className="small">{t('alertSettings.androidClientFeature1')}</span></div>
                                        <div className="list-group-item bg-dark"><i className="fa fa-chevron-right text-warning mr-1" /><span className="small">{t('alertSettings.androidClientFeature2')}</span></div>
                                        <div className="list-group-item bg-dark"><i className="fa fa-chevron-right text-warning mr-1" /><span className="small">{t('alertSettings.androidClientFeature3')}</span></div>
                                        <div className="list-group-item bg-dark"><i className="fa fa-chevron-right text-warning mr-1" /><span className="small">{t('alertSettings.androidClientFeature4')}</span></div>
                                    </ul>
                                    <div className="card-body">
                                        <a href={appUrl} target="_blank" className="card-link"><button className="btn btn-warning btn-block"><i className="fa fa-download mr-2" />{t('alertSettings.downloadButton')}</button></a>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>



                    <div className="row  mt-2 justify-content-center mb-4">
                        <div className="col-12 col-md-6 ">

                            <ul className="list-group list-group-flush rounded-lg">


                                <div className="rounded-top bg-dark border-bottom border-secondary p-2 text-center mt-4  text-secondary">
                                   <div> <i className="fa fa-bell mr-2" />{t('alertSettings.currentAlerts')}</div>
                                    <span className="small my-2 text-secondary">{t('alertSettings.currentAlertsDescription')}</span>
                                </div>

                                {this.state.wsAllFeatures.map(ft => {
                                    if (ft) {

                                        return (
                                            <Bounce key={ft.featureId}>

                                                <li className="list-group-item bg-dark py-1" >
                                                    <form onChange={() => this.handleSwitchToggle("switch" + ft.featureId)}>
                                                        <div className="custom-control custom-switch">
                                                            {this.state.wsFeatureNotify[ft.featureId] ?

                                                                <React.Fragment>
                                                                    <input type="checkbox" className="custom-control-input" id={"switch" + ft.featureId} value={ft.featureId + "#"} defaultChecked />
                                                                    <label className="custom-control-label" htmlFor={"switch" + ft.featureId}><i className={"fa fa-" + ft.fa_icon + " fa-2x ml-2 mr-1"} /> {ft.name} </label>
                                                                </React.Fragment>
                                                                :
                                                                <React.Fragment>
                                                                    <input type="checkbox" className="custom-control-input" id={"switch" + ft.featureId} value={ft.featureId} />
                                                                    <label className="custom-control-label" htmlFor={"switch" + ft.featureId}><i className={"fa fa-" + ft.fa_icon + " fa-2x ml-2 mr-1"} /> {ft.name} </label>
                                                                </React.Fragment>

                                                            }
                                                        </div>

                                                    </form>
                                                </li>
                                            </Bounce>
                                        )

                                    }
                                })}
                            </ul>
                            <ButtonGoUp arrayLength={this.state.wsAllFeatures.length} maxLength={5} />
                        </div >
                    </div >

                </div>

            )

        }
    }



}
AlertSettings.contextType = MiContexto
AlertSettings = withTranslation()(AlertSettings);
export default AlertSettings;
