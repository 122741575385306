import React, { useState, useEffect, useContext } from 'react'


import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';


import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import './Buy.css'


import { resizeNavBar } from '../../CommonFunctions'
import { ButtonGoUp } from '../../CommonComponents'
import UseWebService from '../../UseWebService'
import MiContexto from '../../MiContexto'
import Header from './Header';
import { SecurePayment, Terms, Acordion, Benefits, Logos, Footer } from './Components'
import ResellerHeader from './ResellerHeader'
import axios from "axios";

import GetUser from './GetUser';
import GetReferCode from './GetReferCode';
import BuyResult from './BuyResult';

import { useTranslation } from 'react-i18next';
import { set } from 'lodash';


const PAYMENT_REDIRECT_URL = "https://catwatchful.pink/webservice/payment_redirect.php"


export default function Buy() {

    const { t } = useTranslation();

    const activeDevice = useContext(MiContexto)?.activeDevice
    const user = useContext(MiContexto)?.user

    const [userTmp, setUserTmp] = useState(null)
    const [selectedProduct, setSelectedProduct] = useState(null)

    const [reseller, setReseller] = useState(null)

    const [products, setProducts] = useState(null)
    const [featuresInf, setFeaturesInf] = useState(null)

    const [loadingBuyButton, setLoadingBuyButton] = useState(false)

    const [buyResult, setBuyResult] = useState(null)
    const [language, setLanguage] = useState('en')


    useEffect(() => {
        if (userTmp && selectedProduct) handleBuyClick(selectedProduct)
    }, [userTmp])


    useEffect(() => {
        resizeNavBar()

        //Resultado de la compra
        const queryParams = new URLSearchParams(window.location.search);
        let result = queryParams.get('result');
        let uid = queryParams.get('uid');
        //-------


        if (result && uid) {
            setBuyResult({ status: result, uid: uid })
        } else {

            loadProducts()
            loadFeaturesInf()

            if (!user) {
                //Obtener parametros url para buscar reseller
                let arrayUrl = window.location.href.split('/') 
                let referCode = arrayUrl[arrayUrl.length - 1].split('?')[0]
                if (referCode) loadReseller(referCode)

                console.log("Posible reseller", referCode)
            }
        }


        if ((navigator.language || navigator.userLanguage).includes("es")) setLanguage('es')


    }, [])


    const loadProducts = async () => {
        UseWebService("operation=getProducts").then(resp => {
            console.log(resp.data)
            if (resp.statusCode == 200)
                setProducts(resp.data)

        })
    }
    const loadFeaturesInf = async () => {
        UseWebService("operation=getFeaturesInf").then(resp => {
            console.log(resp.data)
            if (resp.statusCode == 200)
                setFeaturesInf(resp.data)
        })
    }

    const loadReseller = async (referCode) => {
        UseWebService("operation=getReseller&referCode=" + referCode).then(resp => {
            if (resp.statusCode == 200) {
                //Reseller existente!
                const reseller = resp.data[0]
                console.log("Reseller", reseller)

                setReseller(reseller)
                putNewVisit(reseller.resellerId, reseller.referCode)
            }
        })
    }

    const handleUserExists = async (email) => {
        return UseWebService("operation=getUsrExists&email=" + email).then(resp => {
            if (resp.statusCode == 200) {
                return resp.data[0].existe
            } else {
                return resp.statusMessage
            }
        })
    }

    const handleCheckAlreadyUsedDiscount = async (email) => {
        return UseWebService("operation=alreadyUsedDiscount&email=" + email).then(resp => {
            if (resp.statusCode == 200) {
                return resp.data
            } else {
                return null
            }
        })
    }

    const handleLogin = async (email, password) => {
        return UseWebService("operation=getUsrDevices&email=" + email + "&pass=" + password).then(resp => {
            if (resp.statusCode == 200) {
                return resp.data
            } else {
                return null
            }
        })
    }




    const handleSaveUser = (email, password, imei) => {
        console.log("handleSaveUser", email, password, imei)

        setUserTmp({ email, password, imei })
        window.$('#getUserModal').modal('hide');
    }


    const handleInputReferCode = async (referCode) => {
        console.log("handleReferCode", referCode)

        return UseWebService("operation=getReseller&referCode=" + referCode).then(resp => {
            console.log(resp.data)

            if (resp.statusCode == 200) {
                //Reseller existente!
                window.$('#GetReferCodeModal').modal('hide');

                const reseller = resp.data[0]
                setReseller(reseller)
                putNewVisit(reseller.resellerId, reseller.referCode)

                console.log("Reseller", reseller)
            } else {
                return "BAD REFER CODE"
            }
        })
    }



    const putNewVisit = (resellerId, referCode) => {
        // if (!localStorage.getItem(`visited_${referCode}`)) {
        //     localStorage.setItem(`visited_${referCode}`, 'true');
            UseWebService("operation=resellerVisit&resId=" + resellerId + "&url=" + document.referrer)
        //}
    }


    async function handleBuyClick(product) {

        setSelectedProduct(product)


        let aUser = user
        if (!aUser) aUser = userTmp

        if (!aUser) {
            //Solicito un usuario para aplicar la suscripcion
            window.$('#getUserModal').modal();
        } else {
            let aEmail = aUser.email

            let aPassword = '0'
            if (aUser.password) aPassword = aUser.password

            let aImei = aUser.imei
            if (activeDevice) aImei = activeDevice.imei

            let aReseller = { resellerId: '0', referCode: '0' }
            if (reseller) aReseller = { resellerId: reseller.resellerId, referCode: reseller.referCode }


            //base64 (userId|PASSWORD|IMEI|resellerId)
            let uid = `${aEmail}|${aPassword}|${aImei}|${aReseller.resellerId}`


            let requestPayUrl = PAYMENT_REDIRECT_URL + `?uid=${uid}
                                                        &email=${aUser.email}
                                                        &product_opt=${product.productId}
                                                        &reseller=${aReseller.resellerId}-${aReseller.referCode}`

            console.log("requestPayUrl", requestPayUrl)


            setLoadingBuyButton(true)
            await axios({
                method: 'GET',
                url: requestPayUrl,
                dataType: 'json',
            }).then(response => {
                //SE OBTUVO LA URL DE PAGO!  :D
                console.log(response)
                window.location.href = response.data

            }).catch(e => {
                console.log("Error", e)
            }).finally(() => {
                setLoadingBuyButton(false)

            });
        }
    }



    const cantDevicesHtml = (cant) => {
        const htmlTag = [];
        for (let i = 1; i <= cant; i++) {
            htmlTag.push(<i className='fa fa-mobile mr-1' />);
        }
        //return htmlTag
        return cant
    }



    if (buyResult) return (<BuyResult buyResult={buyResult} />)

    if (!products || !featuresInf) return (<div className="container-fluid text-center mt-4"><Loader type="Oval" color="#00BFFF" height={100} width={100} />{t('Buy.loadingProducts')}</div>)


    return (

        <React.Fragment>
            {!user && <Header language={language} />}


            <div className="container text-center mt-4 ">

                {!reseller && <div className="">
                    <Bounce><h1 className="text-white pt-0">{t('Buy.chosePlan')}</h1></Bounce>
                    <Fade><h5 className='text-uppercase' >{t('Buy.enjoyPlan')}</h5></Fade>
                    {/* <GetReferCode handleInputReferCode={handleInputReferCode} /> */}
                </div>}



                {reseller && <ResellerHeader reseller={reseller} />}

                <GetUser handleSaveUser={handleSaveUser}
                    handleUserExists={handleUserExists}
                    handleLogin={handleLogin}
                    handleCheckAlreadyUsedDiscount={handleCheckAlreadyUsedDiscount}
                    currentProduct={selectedProduct}
                    reseller={reseller} />

                {/* {paymentUrl &&
                <div>
                    <iframe style={{ width: '100%', height: '600px' }} src={paymentUrl} frameBorder="no" allowFullScreen={true}></iframe>
                </div>
            } */}

                <hr className='bg-secondary'/>

                {reseller && <Fade><h5 className='text-uppercase' >{t('Buy.planesDisp')}</h5></Fade>}


                <div className='row d-flex justify-content-center mb-2'>
                    {products.map(pr => {
                        if (!pr.features) return null

                        return (
                            <div className=" text-left bg-dark mt-2 rounded p-3 mx-2 mb-4" key={pr.productId} style={{ width: "350px", borderColor: "#" + pr.color, borderStyle: "solid", borderWidth: "1px" }}>

                                <Fade>
                                    {/* <img className="card-img-top" style={{ margintop: '16px', width: '40px', height: 'auto' }} src={"data:image/png;base64, " + pr.logo} alt="Card image cap" /> */}

                                    <div className="text-center" style={{ color: "#" + pr.color }}>
                                        <i className="fa fa-angle-right fa-2x mr-1" />
                                        <span className='h2' >{pr.name} </span>

                                        {!reseller ?
                                            <span><span className="h3 ml-2">${pr.price} </span>/ {~~(pr.daysDuration / 30)}{t('Buy.month')} </span>
                                            :
                                            <div>
                                                <div className="h5 ml-2 text-secondary">{t('Buy.antes')} ${pr.price} </div>
                                                <span className=" bg-primary rounded px-2 h-4 text-light ">30% OFF</span>  <span className='h2 ml-0'>${pr.price_reseller}</span> / {~~(pr.daysDuration / 30)}{t('Buy.month')}
                                            </div>
                                        }

                                    </div>


                                    {/* <div className=" mb-2 font-italic">{pr.desc}</div> */}
                                    {/* <hr style={{backgroundColor:"#a3aab1"}} /> */}
                                    <hr style={{ backgroundColor: "#" + pr.color }} />


                                    <div className='mt-3 mb-3'>

                                        <h4 className='mb-0 h5' style={{color:"#818181"}} >{t('Buy.features')}</h4>
                                        <span className='ml-4 text-uppercase text-dark px-1 rounded' style={{ backgroundColor: "#" + pr.color }}><i className='fa fa-mobile ml-1 my-2' /> {t('Buy.devices')}: {cantDevicesHtml(pr.cantDevices)} </span>

                                        <div className='list-group list-group-flush '>
                                            {featuresInf.map(ft => {
                                                if (!ft) return null

                                                const available = pr.features.find((feat) => feat?.featureId === ft.featureId);

                                                let ftName = ft?.name
                                                if (language === 'es') ftName = ft?.name_es

                                                return (
                                                    <Slide left key={ft.featureId}>
                                                        <div className='ml-4 list-group-item bg-dark p-1' key={ft.featureId} style={{fontWeight:"300", fontSize:"0.9em"}}>
                                                            {available ?
                                                                <div className=" text-left" style={{ color: "#" + pr.color }}><i className={`fa fa-${ft?.fa_icon} mr-2`}  style={{fontSize:"1.5em"}}/><span className=" mb-2 ">{ftName}</span> </div>
                                                                :
                                                                <div className=" text-left text-secondary"> <i className={`fa fa-${ft?.fa_icon} mr-2`}style={{fontSize:"1.5em"}} /><span className=" mb-2 "><s>{ftName}</s></span></div>
                                                            }
                                                        </div>
                                                    </Slide>
                                                )

                                            })}
                                        </div>
                                    </div>
                                </Fade>

                                {!loadingBuyButton ?
                                    <button style={{ backgroundColor: "#" + pr.color }} className='btn btn-dark btn-block mt-1 text-dark' onClick={() => handleBuyClick(pr)}><i className='fa fa-shopping-cart mr-1' />{t('Buy.buyNow')}</button>
                                    :
                                    <button style={{ backgroundColor: "#" + pr.color }} disabled className='btn btn-dark btn-block mt-1 text-dark ' ><i className='fa fa-spinner fa-spin mr-1' />{t('Buy.loading')}</button>

                                }
                            </div>
                        )
                    })}
                </div>



                <div className='row mt-1'>
                    <div className='col-12 col-md-6'>
                        <Fade>
                            <SecurePayment />
                        </Fade>
                    </div>

                    <div className='col-12 col-md-6'>
                        <Fade>
                            <Terms />
                        </Fade>
                    </div>
                </div>


                <hr className='bg-secondary' />

                <div className='mt-4 '>
                    <Fade>
                        <Acordion />
                    </Fade>
                    {/* <hr style={{ backgroundColor: "#FFF" }} /> */}
                </div>

                <div className='mb-4' style={{marginTop:"40px"}}>
                    <Zoom top>
                        <Benefits />
                    </Zoom>
                    <hr />
                </div>


                <div className='mt-4 '>
                    <Fade>
                        <Logos />
                    </Fade>
                </div>

                <div className='mt-4 mb-2'>
                    <Footer />
                </div>




            </div>
        </React.Fragment>
    )



}





